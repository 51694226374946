@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-notification-message-container {
  background-color: #E8F0FD;
  color: #195FE7;
  border-radius: 8px;
  padding: 16px 22px;
  width: fit-content;
  white-space: nowrap;
  cursor: default;
  position: relative;
}
.clyde-notification-message-container--fade-on-click {
  cursor: pointer;
}
.clyde-notification-message-container--action:hover {
  background-color: #DBE9FF;
  cursor: pointer;
}
.clyde-notification-message-container--success {
  background-color: #E7FFF3;
  color: #009147;
}
.clyde-notification-message-container--success--action:hover {
  background-color: #D1FFE8;
}
.clyde-notification-message-container--alert {
  background-color: #FCF0F0;
  color: #D52424;
}
.clyde-notification-message-container--alert--action:hover {
  background-color: #FFE8E8;
}

.clyde-notification-message-loading {
  background-color: #195FE7;
  opacity: 25%;
  border-radius: 8px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 8px;
}
.clyde-notification-message-loading--success {
  background-color: #009147;
}
.clyde-notification-message-loading--alert {
  background-color: #D52424;
}

.clyde-notification-message-text {
  size: 100%;
  font-family: "Volte";
  font-size: 16px;
  padding: 8px, 16px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}

.clyde-notification-message-type {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.5V7' stroke='%23195FE7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='12' cy='17' r='1' fill='%23195FE7'/%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23195FE7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: -6px;
  margin-right: 10px;
}
.clyde-notification-message-type--alert {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.5V7' stroke='%23D52424' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='12' cy='17' r='1' fill='%23D52424'/%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23D52424' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.clyde-notification-message-type--success {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23009147' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 13L10 15L15 10' stroke='%23009147' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.clyde-notification-message-action {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: -7px;
  margin-left: 15px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17L15.5 12L10 7' stroke='%23195FE7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.clyde-notification-message-action--success {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17L15.5 12L10 7' stroke='%23009147' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.clyde-notification-message-action--alert {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17L15.5 12L10 7' stroke='%23D52424' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
