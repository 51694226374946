@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-file-upload-container {
  width: 100%;
  font-family: "Volte";
  transition: opacity 200ms ease;
}

.clyde-file-upload {
  outline: 0;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  font-size: 15px;
  text-align: left;
  background: white;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.clyde-file-upload__input:focus ~ .clyde-file-upload__img {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M6.55273 5.33339H7.33273V6.66672V9.33339H8.66607V6.66672V5.33339H9.44607L7.9994 3.88672L6.55273 5.33339Z' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33398 6.66667L8.00065 2L12.6673 6.66667H10.0007V10.6667H6.00065V6.66667H3.33398ZM8.66732 6.66667V5.33333H9.44732L8.00065 3.88667L6.55398 5.33333H7.33398V6.66667V9.33333H8.66732V6.66667Z' fill='%238024E4'/%3E%3Cpath d='M3.33398 12H12.6673V13.3333H3.33398V12Z' fill='%238024E4'/%3E%3C/svg%3E");
}
.clyde-file-upload__input:focus ~ .clyde-file-upload__file-list .clyde-file-upload__file-list__info {
  text-decoration: underline;
}

.clyde-file-upload:hover .clyde-file-upload__img {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M6.55273 5.33339H7.33273V6.66672V9.33339H8.66607V6.66672V5.33339H9.44607L7.9994 3.88672L6.55273 5.33339Z' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33398 6.66667L8.00065 2L12.6673 6.66667H10.0007V10.6667H6.00065V6.66667H3.33398ZM8.66732 6.66667V5.33333H9.44732L8.00065 3.88667L6.55398 5.33333H7.33398V6.66667V9.33333H8.66732V6.66667Z' fill='%238024E4'/%3E%3Cpath d='M3.33398 12H12.6673V13.3333H3.33398V12Z' fill='%238024E4'/%3E%3C/svg%3E");
}
.clyde-file-upload:hover .clyde-file-upload__file-list__info {
  text-decoration: underline;
}

.clyde-file-upload__input {
  height: 0;
  width: 0;
  display: none;
}

.clyde-file-upload__img {
  min-height: 22px;
  max-height: 22px;
  min-width: 22px;
  max-width: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M6.55273 5.33339H7.33273V6.66672V9.33339H8.66607V6.66672V5.33339H9.44607L7.9994 3.88672L6.55273 5.33339Z' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33398 6.66667L8.00065 2L12.6673 6.66667H10.0007V10.6667H6.00065V6.66667H3.33398ZM8.66732 6.66667V5.33333H9.44732L8.00065 3.88667L6.55398 5.33333H7.33398V6.66667V9.33333H8.66732V6.66667Z' fill='%23195FE7'/%3E%3Cpath d='M3.33398 12H12.6673V13.3333H3.33398V12Z' fill='%23195FE7'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: left 0 top 50%, 0 0;
  background-size: 100%;
  margin: 0 20px 0 10px;
}

.clyde-file-upload__file-list {
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
}
.clyde-file-upload__file-list li {
  overflow: hidden;
  text-overflow: ellipsis;
}

.clyde-file-upload__file-list__file {
  margin-bottom: 3px;
}
.clyde-file-upload__file-list__file:last-child {
  margin-bottom: 0;
}

.clyde-file-upload [type=file] {
  min-height: 100%;
  min-width: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
}

.clyde-file-upload-error {
  display: block;
  font-size: 14px;
  color: red;
  margin: 8px 0 0 0;
}

.clyde-file-upload-container--disabled {
  opacity: 0.7;
  pointer-events: none;
  transition: opacity 200ms ease;
}

.clyde-file-upload-container--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease;
}
