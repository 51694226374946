@charset "UTF-8";
@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-checkbox {
  display: flex;
  opacity: 1;
  transition: opacity 200ms ease;
}

.clyde-checkbox-top {
  flex-direction: column;
  align-items: center;
}
.clyde-checkbox-top .clyde-checkbox-button {
  order: 2;
  margin-top: 5px;
}

.clyde-checkbox-right {
  flex-direction: row;
  align-items: center;
}
.clyde-checkbox-right .clyde-checkbox-button {
  margin-right: 5px;
}

.clyde-checkbox-bottom {
  flex-direction: column;
  align-items: center;
}
.clyde-checkbox-bottom .clyde-checkbox-button {
  margin-bottom: 5px;
}

.clyde-checkbox-left {
  flex-direction: row;
  align-items: center;
}
.clyde-checkbox-left .clyde-checkbox-button {
  order: 2;
  margin-left: 5px;
}

.clyde-checkbox-button {
  outline: 0;
  height: 17px;
  min-width: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  font-size: 12px;
  color: #FFFFFF;
  background: #FBFBFB;
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  transition: all 200ms ease;
}
.clyde-checkbox-button:focus {
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
}
.clyde-checkbox-button:checked {
  background: #195FE7;
  border: none;
}
.clyde-checkbox-button:checked:after {
  content: "✔";
  font-size: 12px;
  color: #FFFFFF;
}

.clyde-checkbox-button--clydeBlue:checked {
  background: #195FE7;
}

.clyde-checkbox-button--clydeWhite:checked {
  background: #FFFFFF;
}
.clyde-checkbox-button--clydeWhite:checked:after {
  color: #000000;
}

.clyde-checkbox-button--clydeBlack:checked {
  background: #000000;
}

.clyde-checkbox-button--clydeLightBlue:checked {
  background: #49E4F3;
}

.clyde-checkbox-button--clydeAqua:checked {
  background: #4AF4C6;
}

.clyde-checkbox-button--clydeViolet:checked {
  background: #321798;
}

.clyde-checkbox-button--clydePurple:checked {
  background: #8024E4;
}

.clyde-checkbox-button--clydeGreen:checked {
  background: #05C379;
}

.clyde-checkbox-button--clydeGrey:checked {
  background: #5C5C5C;
}

.clyde-checkbox-label {
  font-family: "Volte";
  cursor: pointer;
}

.clyde-checkbox-label--grey {
  color: #909090;
}

.clyde-checkbox--disabled {
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
}

.clyde-checkbox--hidden {
  opacity: 0;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
}
