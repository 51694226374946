@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.toggle-button__label {
  display: inline-block;
  margin-top: 2px;
  margin-right: 10px;
  font-family: "Volte";
}

.toggle-button__toggle {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  float: right;
  transition: all 250ms ease;
}

.toggle-button__toggle--true {
  text-align: right;
}

.toggle-button--partial {
  background: #5E8FEE;
}

.toggle-button__dot__text {
  position: absolute;
  bottom: 0;
  font-family: "Volte";
}

.toggle-button__dot {
  background: #F3F3F3;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  transition: left 200ms ease-in-out;
}

.toggle-button__toggle--clydeBlue {
  color: #FFFFFF;
  background-color: #195FE7;
}

.toggle-button__toggle--clydeWhite {
  color: #000000;
  background-color: #FFFFFF;
}

.toggle-button__toggle--clydeBlack {
  color: #FFFFFF;
  background-color: #000000;
}

.toggle-button__toggle--clydeLightBlue {
  color: #000000;
  background-color: #49E4F3;
}

.toggle-button__toggle--clydeAqua {
  color: #000000;
  background-color: #4AF4C6;
}

.toggle-button__toggle--clydeViolet {
  color: #FFFFFF;
  background-color: #321798;
}

.toggle-button__toggle--clydePurple {
  color: #FFFFFF;
  background-color: #8024E4;
}

.toggle-button__toggle--clydeGreen {
  color: #FFFFFF;
  background-color: #05C379;
}

.toggle-button__toggle--clydeGrey {
  color: #FFFFFF;
  background-color: #5C5C5C;
}

.toggle-button__toggle--clydeSubduedGrey {
  color: #909090;
  background-color: #F0F0F0;
}
