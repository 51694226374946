@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.content-tile {
  width: 100%;
  background: #FFFFFF;
  padding: 30px;
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-tile-content {
  margin-top: 15px;
}

.content-tile-header {
  padding-bottom: 5px;
}

.content-tile--background-clydeBlue {
  color: #FFFFFF;
  background-color: #195FE7;
}

.content-tile--background-clydeWhite {
  color: #000000;
  background-color: #FFFFFF;
}

.content-tile--background-clydeBlack {
  color: #FFFFFF;
  background-color: #000000;
}

.content-tile--background-clydeLightBlue {
  color: #000000;
  background-color: #49E4F3;
}

.content-tile--background-clydeAqua {
  color: #000000;
  background-color: #4AF4C6;
}

.content-tile--background-clydeViolet {
  color: #FFFFFF;
  background-color: #321798;
}

.content-tile--background-clydePurple {
  color: #FFFFFF;
  background-color: #8024E4;
}

.content-tile--background-clydeGreen {
  color: #FFFFFF;
  background-color: #05C379;
}

.content-tile--background-clydeGrey {
  color: #FFFFFF;
  background-color: #5C5C5C;
}

.content-tile--background-clydeSubduedGrey {
  color: #909090;
  background-color: #F0F0F0;
}
