@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.loading-dots {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading-dots--vertical {
  transform: rotate(90deg);
}

.loading-dots__dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  background-color: #195FE7;
  border-radius: 100%;
  margin: 0 4px;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loading-dots__dot--1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading-dots__dot--2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loading-dots__dot--clydeBlue {
  background-color: #195FE7;
}

.loading-dots__dot--clydeWhite {
  background-color: #FFFFFF;
}

.loading-dots__dot--clydeBlack {
  background-color: #000000;
}

.loading-dots__dot--clydeLightBlue {
  background-color: #49E4F3;
}

.loading-dots__dot--clydeAqua {
  background-color: #4AF4C6;
}

.loading-dots__dot--clydeViolet {
  background-color: #321798;
}

.loading-dots__dot--clydePurple {
  background-color: #8024E4;
}

.loading-dots__dot--clydeGreen {
  background-color: #05C379;
}

.loading-dots__dot--clydeGrey {
  background-color: #5C5C5C;
}

.loading-dots__dot--small {
  height: 10px;
  width: 10px;
}

.loading-dots__dot--medium {
  height: 20px;
  width: 20px;
  margin: 0 7px;
}

.loading-dots__dot--large {
  height: 30px;
  width: 30px;
  margin: 0 10px;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
