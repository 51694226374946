@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-label-container {
  background-color: #E8F0FD;
  color: #195FE7;
  border-radius: 8px;
  display: inline-block;
  padding: 8px 16px;
  width: fit-content;
}
.clyde-label-container--grey {
  background-color: #F6F6F6;
  color: #D1D1D1;
}
.clyde-label-container--purple {
  background-color: #F7F0FD;
  color: #8024E4;
}

.clyde-label-text {
  font-family: "Volte Medium";
  font-size: 16px;
  padding: 8px, 16px;
  display: inline-block;
  white-space: nowrap;
  cursor: default;
}
