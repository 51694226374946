@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-select-arrow {
  width: 0;
  height: 0;
}
.clyde-select-arrow--closed {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
}
.clyde-select-arrow--open {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000000;
}

.clyde-select-single {
  outline: none;
  height: 38px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 16px;
  font-family: "Volte";
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 2px 15px 0 15px;
  cursor: pointer;
}

.clyde-select-single::-ms-expand {
  display: none;
}

.clyde-select-single:focus {
  outline: 0;
  background-color: #FFFFFF;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

span.clyde-select-single__unselectable {
  padding-right: 10px;
}

.clyde-select-single__unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.clyde-select-multiple__options-list > .clyde-select__input {
  padding: 2px 15px;
}

.clyde-select__input {
  height: 30px;
  width: 90%;
  border: none;
  font-family: "Volte";
  font-size: 16px;
  border-radius: 5px;
  z-index: 100;
}
.clyde-select__input:focus {
  border: none;
  outline: none;
}

.clyde-select-single__options-list {
  width: 100%;
  display: none;
  position: absolute;
  top: 37px;
  left: 0px;
  background-color: #FFFFFF;
  font-family: "Volte";
  font-size: 16px;
  border-radius: 5px;
  z-index: 100;
}

.clyde-select-single__options-list--open {
  display: block;
  border-bottom: 1px solid #d1d1d1;
}
.clyde-select-single__options-list--open--overflow {
  overflow: auto;
  max-height: 50vh;
}
.clyde-select-single__options-list--open--overflow::-webkit-scrollbar {
  width: 10px;
  background: #F6F6F6;
}
.clyde-select-single__options-list--open--overflow::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 100px;
  border: 1px solid #F6F6F6;
}

.clyde-select-single-option {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 20px;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.clyde-select-single-option:first-child {
  border-top: 1px solid #d1d1d1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.clyde-select-single-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.clyde-select-single-option:hover {
  color: #000;
  background-color: #EEEEEE;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.clyde-select-single-option:first-child:focus, .clyde-select-single-option:first-child:hover {
  border-top: 1px solid #d1d1d1;
}
.clyde-select-single-option div {
  min-width: 30px;
}

.clyde-select-single-option--disabled {
  color: #949494;
}
.clyde-select-single-option--disabled:hover {
  color: #949494;
  font-family: "Volte";
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.clyde-select-single-option--disabled:first-child:focus, .clyde-select-single-option--disabled:first-child:hover {
  border-top: 1px solid #d1d1d1;
}
.clyde-select-single-option--disabled:last-child:focus, .clyde-select-single-option--disabled:last-child:hover {
  border-bottom: 1px solid #d1d1d1;
}

.clyde-select-single-option--selected {
  padding: 0 20px 0 20px;
  background-color: #000;
  color: #FFFFFF;
}

.clyde-select-option-text--multiline {
  max-width: calc(100% - 36px);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.clyde-select-option-text--single-line {
  text-overflow: ellipsis;
  max-width: calc(100% - 36px);
  white-space: nowrap;
  overflow: hidden;
}

.clyde-select-multiple {
  outline: none;
  height: auto;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Volte";
  background-color: #FFFFFF;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  margin: 0;
  cursor: default;
}

.clyde-select-multiple-option {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
}
.clyde-select-multiple-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.clyde-select-multiple-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.clyde-select-multiple-option:not(.clyde-select-multiple-option--disabled):hover {
  color: #000;
  background-color: #EEEEEE;
}
.clyde-select-multiple-option.clyde-select-multiple-option--disabled {
  cursor: default;
}
.clyde-select-multiple-option span {
  min-width: 30px;
}

.clyde-select-multiple-option--label {
  pointer-events: none;
  cursor: default;
}

div.clyde-select-multiple-option-input {
  position: relative;
  outline: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #949494;
  border-radius: 2px;
  box-sizing: border-box;
  margin: 0 12px 0 0;
  appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  -webkit-appearance: none;
}
div.clyde-select-multiple-option-input.clyde-select-multiple-option-input--selected {
  background-color: #000;
  border: 2px solid #000;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.97021 5L4.97021 8L11.9702 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 40% 50%;
}
div.clyde-select-multiple-option-input.clyde-select-multiple-option-input--disabled {
  cursor: default;
}
div.clyde-select-multiple-option-input.clyde-select-multiple-option-input--disabled + span {
  color: #949494;
}

.clyde-select {
  transition: opacity 200ms ease;
  min-width: 90px;
}

.clyde-select--disabled {
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
  color: #949494;
}
.clyde-select--disabled:hover {
  background-color: none;
}
.clyde-select--disabled .clyde-select-arrow--closed {
  border-top: 5px solid #949494;
}

.clyde-select--hidden {
  opacity: 0;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
}
