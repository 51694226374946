@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-back-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: text-shadow 0.2s ease, background-color 0.2s ease;
}

.clyde-back-button:focus,
.clyde-back-button:hover {
  outline: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: text-shadow 0.2s ease;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeBlue,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeBlue {
  background-color: #195FE7;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeWhite,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeWhite {
  background-color: #FFFFFF;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeBlack,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeBlack {
  background-color: #000000;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeLightBlue,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeLightBlue {
  background-color: #49E4F3;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeAqua,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeAqua {
  background-color: #4AF4C6;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeViolet,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeViolet {
  background-color: #321798;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydePurple,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydePurple {
  background-color: #8024E4;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeGreen,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeGreen {
  background-color: #05C379;
}
.clyde-back-button:focus .clyde-back-button__circle__hover--clydeGrey,
.clyde-back-button:hover .clyde-back-button__circle__hover--clydeGrey {
  background-color: #5C5C5C;
}

.clyde-back-button__circle {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #195FE7;
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.2s ease;
}

.clyde-back-button__circle--clydeBlue {
  background-color: #195FE7;
}

.clyde-back-button__circle--clydeWhite {
  background-color: #FFFFFF;
}
.clyde-back-button__circle--clydeWhite .clyde-back-button__circle__arrow {
  border: solid #000000;
  border-width: 0 3px 3px 0;
}

.clyde-back-button__circle--clydeBlack {
  background-color: #000000;
}

.clyde-back-button__circle--clydeLightBlue {
  background-color: #49E4F3;
}

.clyde-back-button__circle--clydeAqua {
  background-color: #4AF4C6;
}

.clyde-back-button__circle--clydeViolet {
  background-color: #321798;
}

.clyde-back-button__circle--clydePurple {
  background-color: #8024E4;
}

.clyde-back-button__circle--clydeGreen {
  background-color: #05C379;
}

.clyde-back-button__circle--clydeGrey {
  background-color: #5C5C5C;
}

.clyde-back-button__circle__arrow {
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  padding: 4px;
  margin: 0 0 0 2px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.clyde-back-button__text {
  font-family: "Volte";
  color: #000000;
}
