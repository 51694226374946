@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-text-input {
  height: 38px;
  width: 100%;
  font-family: "Volte";
  font-size: 15px;
  color: #000000;
  line-height: normal;
  background-color: #FFFFFF;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 6px 17px 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.clyde-text-input:focus {
  outline: 0;
}

.clyde-text-input--focus-darken:focus {
  outline: 0;
  background-color: #D9D9D9;
}

.clyde-text-input--subdued {
  background-color: #F0F0F0;
}
.clyde-text-input--subdued::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B1B1B1;
  opacity: 1; /* Firefox */
}
.clyde-text-input--subdued:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B1B1B1;
}
.clyde-text-input--subdued::-ms-input-placeholder { /* Microsoft Edge */
  color: #B1B1B1;
}

.clyde-text-input--disabled {
  background-color: #F0F0F0;
  border: 1px solid #F0F0F0;
  pointer-events: none;
  cursor: initial;
}
.clyde-text-input--disabled::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B1B1B1;
  opacity: 1; /* Firefox */
}
.clyde-text-input--disabled:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B1B1B1;
}
.clyde-text-input--disabled::-ms-input-placeholder { /* Microsoft Edge */
  color: #B1B1B1;
}

.clyde-text-input--hidden {
  width: 0px;
  opacity: 0;
  letter-spacing: -8px;
  cursor: default;
}

.clyde-text-input--search {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L16.65 16.65' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 95% 50%;
}
