@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-radio {
  display: flex;
  opacity: 1;
  transition: opacity 200ms ease;
}

.clyde-radio-top {
  flex-direction: column;
  align-items: center;
}
.clyde-radio-top .clyde-radio-button {
  order: 2;
  margin-top: 5px;
}

.clyde-radio-right {
  flex-direction: row;
  align-items: center;
}
.clyde-radio-right .clyde-radio-button {
  margin-right: 5px;
}

.clyde-radio-bottom {
  flex-direction: column;
  align-items: center;
}
.clyde-radio-bottom .clyde-radio-button {
  margin-bottom: 5px;
}

.clyde-radio-left {
  flex-direction: row;
  align-items: center;
}
.clyde-radio-left .clyde-radio-button {
  order: 2;
  margin-left: 5px;
}

.clyde-radio-label {
  font-family: "Volte";
  cursor: pointer;
}

.clyde-radio-label--grey {
  color: #909090;
}

.clyde-radio--disabled {
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
  color: #949494;
}

.clyde-radio--hidden {
  opacity: 0;
  cursor: default;
  pointer-events: none;
  transition: opacity 200ms ease;
}
