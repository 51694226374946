@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.dropdown-button {
  position: relative;
  font-family: "Volte";
}

.dropdown-button__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  background-color: #195FE7;
  border: 1px solid #195FE7;
  height: 38px;
  width: 132px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 15px;
  background: #195FE7;
  border-radius: 5px;
  transition: all 350ms ease;
}
.dropdown-button__button:hover {
  color: #FFFFFF;
  background-color: #321798;
  border: 1px solid #321798;
  transition: all 350ms ease;
}
.dropdown-button__button:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #321798;
  border: 1px solid #321798;
  transition: all 350ms ease;
}

.dropdown-button__button--clydeBlue {
  color: #FFFFFF;
  background-color: #195FE7;
  border: 1px solid #195FE7;
}

.dropdown-button__button--clydeWhite {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.dropdown-button__button--clydeBlack {
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
}

.dropdown-button__button--clydeLightBlue {
  color: #000000;
  background-color: #49E4F3;
  border: 1px solid #49E4F3;
}

.dropdown-button__button--clydeAqua {
  color: #000000;
  background-color: #4AF4C6;
  border: 1px solid #4AF4C6;
}

.dropdown-button__button--clydeViolet {
  color: #FFFFFF;
  background-color: #321798;
  border: 1px solid #321798;
}

.dropdown-button__button--clydePurple {
  color: #FFFFFF;
  background-color: #8024E4;
  border: 1px solid #8024E4;
}

.dropdown-button__button--clydeGreen {
  color: #FFFFFF;
  background-color: #05C379;
  border: 1px solid #05C379;
}

.dropdown-button__button--clydeGrey {
  color: #FFFFFF;
  background-color: #5C5C5C;
  border: 1px solid #5C5C5C;
}

.dropdown-button__button__hover--clydeBlue:hover {
  color: #FFFFFF;
  background-color: #195FE7;
  border: 1px solid #195FE7;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeBlue:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #195FE7;
  border: 1px solid #195FE7;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeWhite:hover {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeWhite:focus {
  outline: 0;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeBlack:hover {
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeBlack:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeLightBlue:hover {
  color: #000000;
  background-color: #49E4F3;
  border: 1px solid #49E4F3;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeLightBlue:focus {
  outline: 0;
  color: #000000;
  background-color: #49E4F3;
  border: 1px solid #49E4F3;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeAqua:hover {
  color: #000000;
  background-color: #4AF4C6;
  border: 1px solid #4AF4C6;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeAqua:focus {
  outline: 0;
  color: #000000;
  background-color: #4AF4C6;
  border: 1px solid #4AF4C6;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeViolet:hover {
  color: #FFFFFF;
  background-color: #321798;
  border: 1px solid #321798;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeViolet:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #321798;
  border: 1px solid #321798;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydePurple:hover {
  color: #FFFFFF;
  background-color: #8024E4;
  border: 1px solid #8024E4;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydePurple:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #8024E4;
  border: 1px solid #8024E4;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeGreen:hover {
  color: #FFFFFF;
  background-color: #05C379;
  border: 1px solid #05C379;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeGreen:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #05C379;
  border: 1px solid #05C379;
  transition: all 350ms ease;
}

.dropdown-button__button__hover--clydeGrey:hover {
  color: #FFFFFF;
  background-color: #5C5C5C;
  border: 1px solid #5C5C5C;
  transition: all 350ms ease;
}
.dropdown-button__button__hover--clydeGrey:focus {
  outline: 0;
  color: #FFFFFF;
  background-color: #5C5C5C;
  border: 1px solid #5C5C5C;
  transition: all 350ms ease;
}

.dropdown-button__menu {
  position: absolute;
  width: 350px;
  top: 100%;
  right: 0;
  margin: 5px 0;
  padding: 0;
  list-style-type: none;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 10000;
}
.dropdown-button__menu li {
  height: 50px;
  width: 100%;
}

.dropdown-button__menu__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  border: 0;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}
.dropdown-button__menu__button:focus, .dropdown-button__menu__button:hover {
  outline: 0;
  background-color: #F6F6F6;
  border-radius: 5px;
}

.dropdown-button__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #FFFFFF;
  margin-left: 10px;
  margin-bottom: 2px;
  transition: transform 0.3s ease;
}

.dropdown-button__arrow--open {
  border-left: 6px solid #FFFFFF;
  transform: rotate(90deg);
}
