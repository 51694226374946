@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-text-area {
  height: auto;
  width: auto;
  font-family: "Volte";
  font-size: 15px;
  color: #000000;
  line-height: normal;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: 9px 12px;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}
.clyde-text-area:focus {
  outline: 0;
}

.clyde-text-area--focus-darken:focus {
  outline: 0;
  background-color: #D9D9D9;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}

.clyde-text-area--disabled {
  background-color: #F0F0F0;
  border: 1px solid #F0F0F0;
  pointer-events: none;
  cursor: initial;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}
.clyde-text-area--disabled::placeholder {
  color: #B1B1B1;
  opacity: 1;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}
.clyde-text-area--disabled:-ms-input-placeholder, .clyde-text-area--disabled::-ms-input-placeholder {
  color: #B1B1B1;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}

.clyde-text-area--hidden {
  opacity: 0;
  cursor: default;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border 200ms ease, opacity 200ms ease;
}
