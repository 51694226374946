@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.clyde-steps-container {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
  overflow: hidden;
}

.clyde-steps-container__step-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.clyde-steps-container__step-item:last-child .clyde-steps-container__step-item__step-label__horizontal-line, .clyde-steps-container__step-item:first-child .clyde-steps-container__step-item__step-label__horizontal-line {
  width: 50%;
}
.clyde-steps-container__step-item:first-child .clyde-steps-container__step-item__step-label__horizontal-line {
  right: 0;
}
.clyde-steps-container__step-item:first-child .clyde-steps-container__step-item__step-label__horizontal-line--current {
  background: none;
  background-color: #D1D1D1;
}
.clyde-steps-container__step-item:last-child .clyde-steps-container__step-item__step-label__horizontal-line {
  left: 0;
}
.clyde-steps-container__step-item:last-child .clyde-steps-container__step-item__step-label__horizontal-line--current {
  background: none;
  background-color: #195FE7;
}

.clyde-steps-container__step-item__step-container {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
}

.clyde-steps-container__step-item__step-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  z-index: 2;
  color: #D1D1D1;
  font-family: Volte;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #D1D1D1;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.clyde-steps-container__step-item__step-label__horizontal-line {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 1px;
  background-color: #D1D1D1;
  margin: 0;
  padding: 0;
}

.clyde-steps-container__step-item__step-label__horizontal-line--done {
  background-color: #196FE7;
}

.clyde-steps-container__step-item__step-label__horizontal-line--current {
  background: linear-gradient(to right, #196FE7 50%, #D1D1D1 50%);
}

.clyde-steps-container__step-item__step-label--done {
  background-color: #195FE7;
  color: #FFFFFF;
  border-color: #195FE7;
}

.clyde-steps-container__step-item__step-label--current {
  color: #195FE7;
  border-color: #195FE7;
}

.clyde-steps-container__step-item__step-label--clickable {
  cursor: pointer;
}

.clyde-steps-container__step-item__label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #D1D1D1;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 125%;
  text-transform: uppercase;
}

.clyde-steps-container__step-item__label--done,
.clyde-steps-container__step-item__label--current {
  color: #195FE7;
}
