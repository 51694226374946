@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.small-button {
  height: 25px;
  width: 25px;
  position: relative;
  transition: all 350ms ease;
}

.small-button--hidden {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.small-button--disabled {
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
}

.small-button__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  height: 25px;
  width: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.5s ease, border 0.5s ease;
}
.small-button__button:focus, .small-button__button:hover {
  outline: 0;
}
.small-button__button:focus .small-button__button__arrow, .small-button__button:hover .small-button__button__arrow {
  border-left: 6px solid #FFFFFF;
}

.small-button__button--clydeBlue {
  background-color: #FFFFFF;
  border: solid 2px #195FE7;
}
.small-button__button--clydeBlue.small-button__button--open {
  background-color: #195FE7;
}
.small-button__button--clydeBlue:focus, .small-button__button--clydeBlue:hover {
  background-color: #195FE7;
}

.small-button__button--clydePurple {
  background-color: #FFFFFF;
  border: solid 2px #8024E4;
}
.small-button__button--clydePurple.small-button__button--open {
  background-color: #8024E4;
}
.small-button__button--clydePurple:focus, .small-button__button--clydePurple:hover {
  background-color: #8024E4;
}

.small-button__button--clydeWhite {
  background-color: #FFFFFF;
  border: solid 2px #FFFFFF;
}
.small-button__button--clydeWhite.small-button__button--open {
  background-color: #FFFFFF;
}
.small-button__button--clydeWhite:focus, .small-button__button--clydeWhite:hover {
  background-color: #FFFFFF;
}

.small-button__button--clydeBlack {
  background-color: #FFFFFF;
  border: solid 2px #000000;
}
.small-button__button--clydeBlack.small-button__button--open {
  background-color: #000000;
}
.small-button__button--clydeBlack:focus, .small-button__button--clydeBlack:hover {
  background-color: #000000;
}

.small-button__button--clydeLightBlue {
  background-color: #FFFFFF;
  border: solid 2px #49E4F3;
}
.small-button__button--clydeLightBlue.small-button__button--open {
  background-color: #49E4F3;
}
.small-button__button--clydeLightBlue:focus, .small-button__button--clydeLightBlue:hover {
  background-color: #49E4F3;
}

.small-button__button--clydeAqua {
  background-color: #FFFFFF;
  border: solid 2px #4AF4C6;
}
.small-button__button--clydeAqua.small-button__button--open {
  background-color: #4AF4C6;
}
.small-button__button--clydeAqua:focus, .small-button__button--clydeAqua:hover {
  background-color: #4AF4C6;
}

.small-button__button--clydeViolet {
  background-color: #FFFFFF;
  border: solid 2px #321798;
}
.small-button__button--clydeViolet.small-button__button--open {
  background-color: #321798;
}
.small-button__button--clydeViolet:focus, .small-button__button--clydeViolet:hover {
  background-color: #321798;
}

.small-button__button--clydeGreen {
  background-color: #FFFFFF;
  border: solid 2px #05C379;
}
.small-button__button--clydeGreen.small-button__button--open {
  background-color: #05C379;
}
.small-button__button--clydeGreen:focus, .small-button__button--clydeGreen:hover {
  background-color: #05C379;
}

.small-button__button--clydeGrey {
  background-color: #FFFFFF;
  border: solid 2px #5C5C5C;
}
.small-button__button--clydeGrey.small-button__button--open {
  background-color: #5C5C5C;
}
.small-button__button--clydeGrey:focus, .small-button__button--clydeGrey:hover {
  background-color: #5C5C5C;
}

.small-button__button__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: transform 0.3s ease, border 0.5s ease;
}

.small-button__button__arrow--clydeBlue {
  border-left: 6px solid #195FE7;
}

.small-button__button__arrow--clydeWhite {
  border-left: 6px solid #FFFFFF;
}

.small-button__button__arrow--clydeBlack {
  border-left: 6px solid #000000;
}

.small-button__button__arrow--clydeLightBlue {
  border-left: 6px solid #49E4F3;
}

.small-button__button__arrow--clydeAqua {
  border-left: 6px solid #4AF4C6;
}

.small-button__button__arrow--clydeViolet {
  border-left: 6px solid #321798;
}

.small-button__button__arrow--clydePurple {
  border-left: 6px solid #8024E4;
}

.small-button__button__arrow--clydeGreen {
  border-left: 6px solid #05C379;
}

.small-button__button__arrow--clydeGrey {
  border-left: 6px solid #5C5C5C;
}

.small-button__button__arrow--open {
  border-left: 6px solid #FFFFFF;
  transform: rotate(90deg);
}

.small-button-menu {
  width: 300px;
  position: absolute;
  top: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
}
.small-button-menu li {
  height: 50px;
  width: 100%;
}

.small-button-menu--right {
  left: 0;
}

.small-button-menu--left {
  right: 0;
}

.menu__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  height: 100%;
  width: 100%;
  font-family: "Volte";
  font-size: 14px;
  padding: 0 20px;
}
.menu__button:focus, .menu__button:hover {
  outline: 0;
  background-color: #F6F6F6;
  border-radius: 5px;
}

.menu__button--disabled {
  color: red;
  pointer-events: none;
}
