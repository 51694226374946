@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
.large-modal-background {
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 700;
}

.large-modal-background--active {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 300ms ease;
}

.large-modal-background--light {
  background-color: rgba(74, 74, 74, 0.35);
  transition: background-color 300ms ease;
}

.large-modal-foreground {
  height: 0;
  width: 75%;
  position: absolute;
  font-family: "Volte";
  text-align: left;
  background: #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 60px 100px 0 100px;
  overflow: auto;
}

.large-modal-foreground--active {
  height: 90%;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  transition: height 200ms ease;
}

.large-modal-action-complete {
  padding-top: 80px;
}

.large-modal-header__cancel {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
  float: right;
  color: #909090;
  font-size: 30px;
  line-height: 30px;
}
.large-modal-header__cancel:focus, .large-modal-header__cancel:hover {
  outline: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.large-modal-header__subheader {
  text-align: left;
  margin: 0 0 5px 0;
}

.large-modal-loading {
  padding-top: 100px;
}

.large-modal-content {
  margin-top: 20px;
}
